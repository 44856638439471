import { firestore } from "utils/firebase";

// export const cotAccount = "acct_1M49PfFjkJEvnTwL"; //dev
export const cotAccount = "acct_1MuOLqFjVM8m8DG7"; //prod

export const listenForFundraiserChanges = (setFundraisers, cotBearer) => {
	return firestore
		.collection("fundraisers")
		.orderBy("lastUpdated", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						...doc.data(),
						id: doc.id,
						lastUpdated: doc.data().lastUpdated.toDate()
					});
				});

				console.log({ donations });

				let promises = donations.map((item, index) =>
					getUsersPromise(item, item.userId)
				);
				Promise.all(promises).then((users) => {
					let orgPromises = users.map((item, index) => getOrgPromise(item, item.orgId, cotBearer));
					Promise.all(orgPromises).then((orgs) => {
						console.log({ users });
						setFundraisers(orgs);
					})
				});
			},
			(e) => {
				console.log("listenForRecurringDonationChanges err", e);
			}
		);
};

export const listenForDonationsToFundraiser = (orgId, userId, setDonations) => {
	return firestore
		.collection("donations")
		.where("fundraiserUserId", "==", userId + "-" + orgId)
		.orderBy("date", "desc")
		.onSnapshot(
			(querySnapshot) => {
				let donations = [];

				querySnapshot.forEach((doc) => {
					donations.push({
						...doc.data(),
						id: doc.id
					});
				});

				setDonations(donations);
			},
			(e) => {
				console.log("listenForDonationsToFundraiser err", e);
			}
		);
};

export const getUsersWithZeroDollarFundraisers = (setFundraisers, cotBearer) => {
	return firestore.collection('users')
		.where('supportedNonprofit.amountRaised', '==', 0)
		.onSnapshot(
			(querySnapshot) => {
				let fundraisers = [];
				querySnapshot.forEach((doc) => {
					const userData = doc.data();
					const fundraiser = {
						backers: [],
						lastUpdated: new Date(),
						orgId: userData?.supportedNonprofit?.id,
						totalDonations: 0,
						totalDonationsCount: 0,
						userEmail: userData.email,
						userId: doc.id,
						user: doc.data(),
						active: true
					}
					fundraisers.push(fundraiser);
				});

				let promises = fundraisers.map((item, index) =>
					getOrgPromise(item, item.orgId, cotBearer)
				);
				Promise.all(promises).then((orgs) => {
					setFundraisers(orgs);
				});
			},
			(e) => {
				console.log("getUsersWithZeroDollarFundraisers err", e);
			})
}


export const getUsersPromise = (obj, id) => {
	return new Promise((resolve, reject) => {
		firestore
			.collection("users")
			.doc(id)
			.get()
			.then((doc) => {
				//check if user active here
				if (doc.exists) {
					let user = {
						id: doc.id,
						...doc.data()
					};
					obj.user = user;
					resolve(obj);
				} else {
					resolve(obj);
				}
			});
	});
};

export const getOrgPromise = (obj, id, bearer) => {
	return new Promise((resolve, reject) => {
		if (obj.cot || id.includes('-') || obj?.isVM) {
			console.log('is cot')
			obj.cot = true;
			const url = `https://s.poweredbycharity.com/api/principal/search?search=${id}&result_count=10&page=1`;
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${bearer}`,
					Accept: "application/json"
				}
			};

			fetch(url, options)
				.then((res) => res.json())
				.then((data) => {
					if (!data?.hits?.length) {
						return resolve(obj);
					}
					// console.log("data", data);

					console.log("here", data);
					const results = data?.hits?.map((hit) => {
						let ein = hit.ein;
						ein = String(ein);
						if (ein?.length === 8) {
							ein = "0" + ein;
						}
						if (ein?.length === 9) {
							ein = ein.substring(0, 2) + "-" + ein.substring(2, 9);
						} else {
							return;
						}
						return {
							name: hit.name,
							ein: ein,
							id: ein,
							cot: true,
							stripeID: cotAccount,
							stripeComplete: true,
							address: {
								city: hit.city,
								state: hit.state,
								zip: hit.zip,
								street: hit.street
							}
						}
					});
					
					obj.org = results[0];
					resolve(obj);
				});
		}
		firestore
			.collection("organizations")
			.doc(id)
			.get()
			.then((doc) => {
				//check if org active here
				if (doc.exists) {
					let org = {
						id: doc.id,
						...doc.data()
					};
					obj.org = org;
					resolve(obj);
				} else {
					resolve(obj);
				}
			});
	});
};
