import React, { useState, createContext, useEffect, useContext } from "react"
import { useRayze } from "./RayzeContext"
import { getPBCCorporateDonations, getPBCDonations } from "services/pbc-service"

export const PBCContext = createContext()

export const PBCProvider = ({ children }) => {
  const { selectedOrg } = useRayze()

  const [page, setPage] = useState(1);
  const [recentDonations, setRecentDonations] = useState([])
  const [userDonations, setUserDonations] = useState([])
  const [corporateDonations, setCorporateDonations] = useState([])

  useEffect(() => {
    if (!selectedOrg) return
    getPBCDonations(setUserDonations)
    getPBCCorporateDonations(setCorporateDonations)
  }, [selectedOrg])

  useEffect(() => {

    //concat recent donations and corporate donations and sort by date
    const allDonations = userDonations.concat(corporateDonations)
    allDonations.sort((a, b) => b.date.toDate() - a.date.toDate())
    setRecentDonations(allDonations)

  }, [userDonations, corporateDonations])

  function getDonations() {
    getPBCDonations(setUserDonations)
    getPBCCorporateDonations(setCorporateDonations)
  }

  function nextPage() {
    // fetchMoreContacts(lastDoc, setLastDoc, contacts, setContacts);
    setPage(page + 1);
  }



  return (
    <PBCContext.Provider
      value={{
        recentDonations,
        page, setPage,
        getDonations,
        nextPage
      }}
    >
      {children}
    </PBCContext.Provider>
  )
}

export const usePBC = () => useContext(PBCContext)
