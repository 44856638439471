import React, { useState, useEffect } from "react";
import { StyleSheet, View } from "react-native";
import { COLORS } from "constants/Colors";
import { PrimaryText } from "components/Common/PrimaryText";
import { useOrganization } from "context/OrganizationContext";
import { useNavigate } from "react-router";
import { Links } from "constants/Links";
import { ScrollView } from "react-native-web";
import { searchOrg } from "services/organizations-service";
import { useCRM } from "context/CRMContext";
import { saveContact } from "services/crm-service";
import {
  CRM_ContactStatus,
  CRM_LeadSources,
  CRM_NumOfEmployees,
  CRM_Status,
} from "constants/Data";
import { dollarFormatter, getUsersName } from "utils/helpers";
import { Header } from "components/Header";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { CRMLogCard } from "components/Cards/CRMLogCard";
import { useRayze } from "context/RayzeContext";
import { ContactForm } from "components/CRM/ContactForm";

const pageSize = 10;

export const CRMDetail = () => {
  const navigate = useNavigate();
  const { setSelectedOrg } = useOrganization();
  const {
    contacts,
    selectedContact,
    setSelectedContact,
    linkedOrg,
    setLinkedOrg,
    causes,
    logs,
  } = useCRM();
  const { team } = useRayze();

  const [showEditContactModal, setShowEditContactModal] = useState(false);
  const [editedContact, setEditedContact] = useState({ ...selectedContact });
  const [orgSearch, setOrgSearch] = useState("");
  const [searchedOrgs, setSearchedOrgs] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  const userDetails = [
    {
      title: "Status",
      value: CRM_Status.find((x) => x.value === selectedContact?.status)?.label,
    },
    {
      title: "Lead Owner",
      value: getUsersName(
        team.find((x) => x.id === selectedContact?.leadOwner)
      ),
    },
    {
      title: "Website",
      value: selectedContact?.website,
    },
    {
      title: "Address",
      value: `${
        selectedContact?.address?.line1
          ? selectedContact?.address?.line1 + "\n"
          : ""
      }${selectedContact?.address?.city}, ${
        selectedContact?.address?.state +
        (selectedContact?.address?.zipCode ? "," : "")
      } ${selectedContact?.address?.zipCode || ""} `,
      capitalize: true,
      // value: "1234 Fake Street Lumberton, SC 28358",
    },
    {
      title: "Estimated Revenue",
      value: selectedContact?.estimatedRevenue ? dollarFormatter.format(selectedContact?.estimatedRevenue) : 'N/A',
    },
    {
      title: "# of Employees",
      value: CRM_NumOfEmployees.find(
        (x) => x.value === selectedContact?.numOfEmployees
      )?.label,
    },
    {
      title: "Lead Source",
      value: CRM_LeadSources.find(
        (x) => x.value === selectedContact?.numOfEmployees
      )?.label,
    },
  ];

  const userDetails2 = [
    {
      title: "Notes",
      value: selectedContact?.notes,
    },
  ];

  useEffect(() => {
    setSaveDisabled(
      !editedContact.name ||
        !editedContact.status ||
        !editedContact.leadOwner ||
        !editedContact.estimatedRevenue ||
        !editedContact.website
    );
  }, [editedContact]);

  useEffect(() => {
    if (!orgSearch || !showEditContactModal) {
      setOrgSearch("");
      setSearchedOrgs([]);
    } else {
      searchOrg(orgSearch, setSearchedOrgs);
    }
  }, [orgSearch, showEditContactModal]);

  //autogenerate info for linked orgs
  useEffect(() => {
    if (!linkedOrg) return;
    const {
      name,
      website,
      stripeID = "",
      profilePic = "",
      causes = [],
      address = {},
      mailingAddress = "",
      verified = false,
    } = linkedOrg;
    const updatedContact = {
      ...editedContact,
      name,
      status: stripeID ? 4 : verified ? 5 : 3,
      website: website ? website : selectedContact?.website,
      profilePic,
      causes,
      address,
      mailingAddress,
    }
    if (updatedContact.status !== editedContact.status) {
      saveContact(updatedContact, () => {
        let find = contacts.findIndex((x) => x.id === updatedContact?.id);
        if (find > -1) {
          contacts[find] = { ...updatedContact };
        }
        setSelectedContact({...updatedContact});
      });
    }
    setEditedContact(updatedContact);

  }, [linkedOrg]);

  function saveEditedContact() {
    saveContact(editedContact, (contact) => {
      // contacts.push(contact);
      setSelectedContact({ ...editedContact });
      let findIndex = contacts.findIndex((x) => x.id === editedContact.id);
      contacts[findIndex] = { ...editedContact };
      setShowEditContactModal(false);
    });
  }

  function viewOrgProfileSelected() {
    // getOrg(selectedContact?.linkedOrg, (org) => {
    setSelectedOrg(linkedOrg);
    navigate(Links.NonprofitDetail);
    // });
  }

  const leadOptions = team.map((x) => {
    return { value: x.id, label: getUsersName(x) };
  });

  return (
    <View style={styles.container}>
      <Header
        title={selectedContact?.name}
        onBack={() => navigate(-1)}
        onRight={viewOrgProfileSelected}
        rightText={selectedContact?.linkedOrg ? "View Org Profile" : null}
        edit={() => setShowEditContactModal(true)}
        // exportData={formatMostValuableItemsReport(filtered)}
      />

      {/** ROW 1 */}
      <View style={styles.info}>
        <View style={styles.infoContainer}>
          {userDetails.map((u, i) => {
            const { title, value } = u;
            // if (!value) return;
            return (
              <View style={styles.infoTextRow} key={i}>
                <View style={{ marginRight: 12 }} className="text-right mr-4">
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ width: 180, textAlign: "right" }}
                  >
                    {title}
                  </PrimaryText>
                </View>
                <PrimaryText
                  fontSize={16}
                  fontWeight={600}
                  style={u?.capitalize && { textTransform: "capitalize" }}
                >
                  {value}
                </PrimaryText>
              </View>
            );
          })}
        </View>

        <ScrollView style={styles.causesContainer}>
          <PrimaryText fontSize={16} fontWeight={600}>
            {"Causes"}
          </PrimaryText>
          <View style={styles.causes}>
            {causes &&
              causes.map((item) => (
                <View style={styles.cause} key={item.id}>
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ color: COLORS.primary.lightBlue }}
                  >
                    {item.name}
                  </PrimaryText>
                  {/* <FaIcons.FaTimes /> */}
                </View>
              ))}
          </View>
        </ScrollView>
      </View>

      {/** ROW 2 - CONTACTS */}
      <View style={styles.info}>
        <View style={styles.infoContainer}>
          <PrimaryText fontSize={16} fontWeight={600}>
            {"Contact(s)"}
          </PrimaryText>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Phone</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {selectedContact.contacts &&
                selectedContact.contacts.map((u, i) => {
                  let {
                    name = "",
                    email = "",
                    title = "",
                    phone = "",
                    status = 0,
                  } = u;
                  // if (!value) return;
                  return (
                    <TableRow>
                      <TableCell
                        style={{ marginRight: 12 }}
                        className="text-right mr-4"
                      >
                        <PrimaryText fontSize={14} fontWeight={400}>
                          {name}
                        </PrimaryText>
                      </TableCell>
                      <TableCell>
                        <PrimaryText fontSize={14} fontWeight={400}>
                          {
                            CRM_ContactStatus.find((x) => x.value === status)
                              ?.label
                          }
                        </PrimaryText>
                      </TableCell>
                      <TableCell>
                        <PrimaryText fontSize={14} fontWeight={400}>
                          {title}
                        </PrimaryText>
                      </TableCell>
                      <TableCell>
                        <PrimaryText fontSize={14} fontWeight={400}>
                          {email}
                        </PrimaryText>
                      </TableCell>
                      <TableCell>
                        <PrimaryText fontSize={14} fontWeight={400}>
                          {phone}
                        </PrimaryText>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </View>
      </View>

      {/** ROW 3 - NOTES */}
      <View style={styles.info}>
        <View style={styles.infoContainer}>
          {userDetails2.map((u, i) => {
            const { title, value } = u;
            // if (!value) return;
            return (
              <View style={styles.infoTextRow} key={i}>
                <View style={{ marginRight: 12 }} className="text-right mr-4">
                  <PrimaryText
                    fontSize={14}
                    fontWeight={400}
                    style={{ width: 180, textAlign: "right" }}
                  >
                    {title}
                  </PrimaryText>
                </View>
                <PrimaryText
                  fontSize={16}
                  fontWeight={600}
                  style={u?.capitalize && { textTransform: "capitalize" }}
                >
                  {value}
                </PrimaryText>
              </View>
            );
          })}
        </View>
      </View>

      <View style={styles.info}>
        <View style={styles.infoContainer}>
          <PrimaryText fontSize={16} fontWeight={600}>
            {"Status History"}
          </PrimaryText>
          <Table
            className="table"
            style={{ borderTopLeftRadius: 12, overflow: "hidden" }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell style={{ textAlign: "left" }}>Log</TableCell>
                <TableCell>User</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {logs &&
                logs.map((item, i) => {
                  return <CRMLogCard item={item} />;
                })}
            </TableBody>
          </Table>
        </View>
      </View>

      {/** INVITE USER */}
      <ContactForm
        title={"Edit Contact"}
        contact={editedContact}
        setContact={setEditedContact}
        showModal={showEditContactModal}
        setShowModal={setShowEditContactModal}
        onSave={saveEditedContact}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100%",
    paddingVertical: 20,
    backgroundColor: COLORS.primary.white,
  },
  header: {
    color: COLORS.primary.darkBlue,
  },
  stats: {
    flex: 1,
    flexDirection: "row",
    padding: 27,
    justifyContent: "space-around",
    // justifyContent:'center',
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  statContainer: {
    alignItems: "center",
  },
  info: {
    flexDirection: "row",
    marginBottom: 16,
    maxHeight: 300,
  },
  infoContainer: {
    flex: 1,
    padding: 20,
    justifyContent: "space-around",
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
  },
  infoTextRow: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 6,
  },
  causesContainer: {
    flex: 1,
    // height: 200,
    padding: 20,
    backgroundColor: COLORS.primary.offWhite,
    borderRadius: 12,
    marginLeft: 16,
  },
  causes: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    paddingBottom: 20,
    paddingTop: 12,
  },
  cause: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 4,
    paddingHorizontal: 8,
    marginRight: 8,
    marginBottom: 10,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: COLORS.primary.lightBlue,
  },

  //edit
  button: {
    flexDirection: "row",
    backgroundColor: COLORS.primary.lightBlue,
    // width: 100,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    padding: 8,
    margin: 12,
    borderRadius: 40,
  },
  settingsRow: {
    flexDirection: "row",
    paddingHorizontal: 13,
    paddingVertical: 8,
    alignItems: "center",
    justifyContent: "space-between",
    // borderBottomColor: COLORS.primary.lightGray,
    // borderBottomWidth: 0.5,
  },
  searchContainer: {
    marginHorizontal: 16,
    padding: 8,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  searchedItem: {
    padding: 4,
  },
  inputTitle: {
    paddingLeft: 10,
    marginBottom: 5,
    color: COLORS.primary.darkGray,
  },
  selectedLinkedOrg: {
    flexDirection: "row",
    height: 30,
    borderRadius: 13,
    flexGrow: 1,
    borderWidth: 1,
    borderColor: COLORS.primary.lightGray,
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 12,
    paddingRight: 4,
    backgroundColor: COLORS.primary.offWhite,
  },
  text: {
    fontSize: 14,
    color: COLORS.primary.darkGray,
    fontWeight: 400,
    fontFamily: "Montserrat",
  },
});
